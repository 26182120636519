// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-study-js": () => import("./../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-data-integrations-js": () => import("./../src/pages/data-integrations.js" /* webpackChunkName: "component---src-pages-data-integrations-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nugit-bug-reporting-policy-js": () => import("./../src/pages/nugit-bug-reporting-policy.js" /* webpackChunkName: "component---src-pages-nugit-bug-reporting-policy-js" */),
  "component---src-pages-press-js": () => import("./../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-product-js": () => import("./../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-request-demo-js": () => import("./../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-video-demos-js": () => import("./../src/pages/video-demos.js" /* webpackChunkName: "component---src-pages-video-demos-js" */),
  "component---src-pages-webinars-js": () => import("./../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */),
  "component---src-pages-what-is-data-storytelling-js": () => import("./../src/pages/what-is-data-storytelling.js" /* webpackChunkName: "component---src-pages-what-is-data-storytelling-js" */),
  "component---src-pages-whitepapers-js": () => import("./../src/pages/whitepapers.js" /* webpackChunkName: "component---src-pages-whitepapers-js" */),
  "component---src-templates-blogdetail-js": () => import("./../src/templates/blogdetail.js" /* webpackChunkName: "component---src-templates-blogdetail-js" */),
  "component---src-templates-blogs-by-category-js": () => import("./../src/templates/blogsByCategory.js" /* webpackChunkName: "component---src-templates-blogs-by-category-js" */),
  "component---src-templates-blogs-by-tag-js": () => import("./../src/templates/blogsByTag.js" /* webpackChunkName: "component---src-templates-blogs-by-tag-js" */),
  "component---src-templates-case-study-js": () => import("./../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-event-detail-js": () => import("./../src/templates/event-detail.js" /* webpackChunkName: "component---src-templates-event-detail-js" */),
  "component---src-templates-integration-detail-js": () => import("./../src/templates/integration-detail.js" /* webpackChunkName: "component---src-templates-integration-detail-js" */),
  "component---src-templates-integration-dimensions-metrics-home-js": () => import("./../src/templates/integration-dimensions-metrics-home.js" /* webpackChunkName: "component---src-templates-integration-dimensions-metrics-home-js" */),
  "component---src-templates-integration-dimensions-metrics-js": () => import("./../src/templates/integration-dimensions-metrics.js" /* webpackChunkName: "component---src-templates-integration-dimensions-metrics-js" */),
  "component---src-templates-integration-super-premium-js": () => import("./../src/templates/integration-super-premium.js" /* webpackChunkName: "component---src-templates-integration-super-premium-js" */),
  "component---src-templates-landing-page-2-js": () => import("./../src/templates/landing-page-2.js" /* webpackChunkName: "component---src-templates-landing-page-2-js" */),
  "component---src-templates-landing-page-3-js": () => import("./../src/templates/landing-page-3.js" /* webpackChunkName: "component---src-templates-landing-page-3-js" */),
  "component---src-templates-landing-page-4-js": () => import("./../src/templates/landing-page-4.js" /* webpackChunkName: "component---src-templates-landing-page-4-js" */),
  "component---src-templates-landing-page-js": () => import("./../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-resource-js": () => import("./../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-story-js": () => import("./../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-video-demo-js": () => import("./../src/templates/video-demo.js" /* webpackChunkName: "component---src-templates-video-demo-js" */),
  "component---src-templates-webinar-demo-js": () => import("./../src/templates/webinar-demo.js" /* webpackChunkName: "component---src-templates-webinar-demo-js" */),
  "component---src-templates-webinar-detail-js": () => import("./../src/templates/webinar-detail.js" /* webpackChunkName: "component---src-templates-webinar-detail-js" */),
  "component---src-templates-white-paper-js": () => import("./../src/templates/white-paper.js" /* webpackChunkName: "component---src-templates-white-paper-js" */)
}

